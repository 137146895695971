import React from "react";
import theme from "theme";
import { Theme, Box, Text, Section, Icon, LinkBox, Em, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { IoIosCheckboxOutline, IoIosArrowRoundForward } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Higland Golf Kulübü
			</title>
			<meta name={"description"} content={"Olağanüstü bir golf deneyimi için ilk destinasyonunuz olan Highland Golf Kulübü'nü keşfedin. Güzel sahamızı, birinci sınıf tesislerimizi keşfedin ve golf tutkunlarından oluşan topluluğumuza katılın."} />
			<meta property={"og:title"} content={"Higland Golf Kulübü"} />
			<meta property={"og:description"} content={"Olağanüstü bir golf deneyimi için ilk destinasyonunuz olan Highland Golf Kulübü'nü keşfedin. Güzel sahamızı, birinci sınıf tesislerimizi keşfedin ve golf tutkunlarından oluşan topluluğumuza katılın."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
		</Helmet>
		<Components.Headernew />
		<Section md-padding="70px 0 80px 0" quarkly-title="Product-2" padding="60px 0 60px 0">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z) 0 0/contain no-repeat scroll padding-box"
					padding="100px 50px 100px 50px"
					md-margin="0px 0px 50px 0px"
					md-padding="50px 50px 50px 50px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					padding="0px 40px 0px 40px"
					lg-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 40px 0px" font="normal 500 42px/1.2 --fontFamily-serifGaramond" sm-margin="0px 0px 30px 0px">
						Golf Kulübü Green Horizons'a Hoş Geldiniz
					</Text>
					<Text margin="0px 0px 50px 0px" font="normal 300 20px/1.5 --fontFamily-sans" color="#656b70">
						Yemyeşil manzaralar ve bakımlı farvatlarla çevrili Green Horizons Golf Kulübü, rakipsiz bir golf deneyimi sunan bir yerdir. Kulübümüz, her seviyeden golf tutkunu için sakin ama zorlayıcı bir ortam sunarak oyununuzu geliştirme fırsatı sunar. Sizleri özenle bakımlı sahamızın güzelliklerine dalmaya ve Green Horizons'ı golfçüler için bir cennet yapan olanakların tadını çıkarmaya davet ediyoruz.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						lg-align-self="center"
						md-align-self="auto"
						sm-flex-direction="column"
						sm-align-items="center"
						sm-align-self="center"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-10"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					Golf Kulübü Green Horizons Hakkında
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="60%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					Golf Kulübü Green Horizons'taki misyonumuz, birinci sınıf ekipman, kusursuz hizmet ve oyuna olan tutkumuz sayesinde olağanüstü bir golf deneyimi sunmaktır. Dünyaca ünlü mimarlar tarafından tasarlanan sahamız, doğal güzellikleri stratejik zorluklarla birleştirerek her turu hem keyifli hem de teşvik edici bir deneyime dönüştürüyor. İster deneyimli bir profesyonel ister yeni başlayan biri olun, sahamız becerilerinizi geliştirmeniz ve spordan keyif almanız için çeşitli zorluklar sunuyor.
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="48px"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="io"
						icon={IoIosCheckboxOutline}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							Dünya Standartlarında Saha Tasarımı
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Sahamız, çeşitli manzaralar ve büyüleyici manzaralar sayesinde her seviyeden golfçüye ilham vermek ve onları zorlamak için tasarlanmıştır.
						</Text>
					</Box>
					<LinkBox
						flex-direction="row"
						align-items="center"
						justify-content="flex-start"
						color="--red"
						hover-opacity="0.7"
						transition="--opacityOut"
					>
						<Text margin="0px 0px 0px 0px" letter-spacing="0.3px">
							Daha Fazla Bilgi Edinin
						</Text>
						<Icon category="io" icon={IoIosArrowRoundForward} size="24px" />
					</LinkBox>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="io"
						icon={IoIosCheckboxOutline}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							Kusursuz Hizmet
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Saha bakım ekibimiz, yıl boyunca en iyi oyun deneyimini sağlamak için sahayı mükemmel durumda tutmak için yorulmadan çalışır.
						</Text>
					</Box>
					<LinkBox
						flex-direction="row"
						align-items="center"
						justify-content="flex-start"
						color="--red"
						hover-opacity="0.7"
						transition="--opacityOut"
					>
						<Text margin="0px 0px 0px 0px" letter-spacing="0.3px">
							Daha Fazla Bilgi Edinin
						</Text>
						<Icon category="io" icon={IoIosArrowRoundForward} size="24px" />
					</LinkBox>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="io"
						icon={IoIosCheckboxOutline}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							Kapsamlı Olanaklar
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Gurme restoranımızdan tam donanımlı profesyonel mağazamıza kadar, unutulmaz bir golf günü için ihtiyacınız olan her şeyi sunuyoruz.
						</Text>
					</Box>
					<LinkBox
						flex-direction="row"
						align-items="center"
						justify-content="flex-start"
						color="--red"
						hover-opacity="0.7"
						transition="--opacityOut"
					>
						<Text margin="0px 0px 0px 0px" letter-spacing="0.3px">
							Daha Fazla Bilgi Edinin
						</Text>
						<Icon category="io" icon={IoIosArrowRoundForward} size="24px" />
					</LinkBox>
				</Box>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="48px"
				margin="2rem 0px 0px 0px"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="io"
						icon={IoIosCheckboxOutline}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							Kişiselleştirilmiş Hizmet
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Personelimiz, varışınızdan ayrılışınıza kadar kesintisiz ve keyifli bir deneyim sağlamak için olağanüstü hizmet sunmaya çalışır.
						</Text>
					</Box>
					<LinkBox
						flex-direction="row"
						align-items="center"
						justify-content="flex-start"
						color="--red"
						hover-opacity="0.7"
						transition="--opacityOut"
					>
						<Text margin="0px 0px 0px 0px" letter-spacing="0.3px">
							Daha Fazla Bilgi Edinin
						</Text>
						<Icon category="io" icon={IoIosArrowRoundForward} size="24px" />
					</LinkBox>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="io"
						icon={IoIosCheckboxOutline}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							Topluluk ve Dostluk
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Canlı golf tutkunu topluluğumuza katılın ve Green Horizons'ı sadece bir golf kulübü olmaktan çıkaran özel etkinlikler, turnuvalar ve sosyal toplantılara katılın.
						</Text>
					</Box>
					<LinkBox
						flex-direction="row"
						align-items="center"
						justify-content="flex-start"
						color="--red"
						hover-opacity="0.7"
						transition="--opacityOut"
					>
						<Text margin="0px 0px 0px 0px" letter-spacing="0.3px">
							Daha Fazla Bilgi Edinin
						</Text>
						<Icon category="io" icon={IoIosArrowRoundForward} size="24px" />
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Section padding="120px 0 130px 0" md-padding="80px 0 80px 0" quarkly-title="Reviews-13">
			<Override slot="SectionContent" md-padding="0px 0 0px 0" />
			<Text margin="0px 0px 20px 0px" color="--darkL2" font="normal 600 46px/1.2 --fontFamily-serifGaramond" text-align="center">
				Olağanüstü Koşullar ve Hizmetler
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0 0 0 0"
				border-radius="8px"
				margin="0px 0 0 0"
				display="flex"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 0 0px"
				md-flex-direction="column"
				lg-flex-direction="column"
				lg-padding="0 0 0 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 70px 0px 0"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					md-width="100%"
					lg-width="100%"
					lg-margin="0px 0px 0 0px"
					lg-padding="0px 0px 0px 0"
					sm-padding="0 0px 0px 0"
					margin="0px 0 0px 0px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="0 0 0 2px"
						border-style="solid"
						border-color="#333b41"
						padding="0px 0px 0px 25px"
						margin="0px 0px 40px 0px"
						lg-margin="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 0 0px" font="--lead" color="#333b41">
							<Em>
								Clubhouse'ımız, hem kulüp üyeleri hem de misafirler için Green Horizons'un kalbidir. Gurme restoranımızda yemeklerin tadını çıkarın, barda bir içecekle rahatlayın veya lüks lounge alanımızda dinlenin. Profesyonel mağazamız en son ekipman ve kıyafetlerle donatılmış olup, sahada mükemmel bir gün geçirmek için ihtiyacınız olan her şeye sahip olmanızı sağlar. Ayrıca, driving range ve putting green gibi en son teknolojiye sahip antrenman alanlarımız, oyununuzu geliştirmeniz için ideal koşullar sunar.
							</Em>
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					align-items="center"
					justify-content="center"
					lg-width="100%"
					lg-order="-1"
					lg-margin="0px 0px 40px 0px"
					sm-margin="0px 0px 40px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-2.jpg?v=2024-08-01T08:01:46.063Z"
						display="block"
						margin="0px 0 0px 0px"
						width="auto"
						height="500px"
						border-radius={0}
						border-color="#b0b7bb"
						object-fit="cover"
						sm-height="350px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/66ab354615ac54001870d61d/images/2-2.jpg?v=2024-08-01T08%3A01%3A46.063Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ab354615ac54001870d61d/images/2-2.jpg?v=2024-08-01T08%3A01%3A46.063Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ab354615ac54001870d61d/images/2-2.jpg?v=2024-08-01T08%3A01%3A46.063Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ab354615ac54001870d61d/images/2-2.jpg?v=2024-08-01T08%3A01%3A46.063Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ab354615ac54001870d61d/images/2-2.jpg?v=2024-08-01T08%3A01%3A46.063Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ab354615ac54001870d61d/images/2-2.jpg?v=2024-08-01T08%3A01%3A46.063Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ab354615ac54001870d61d/images/2-2.jpg?v=2024-08-01T08%3A01%3A46.063Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Headernew />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac2cae8900d0022f35234"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});